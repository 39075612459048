import { useAuthorizer } from "@authorizerdev/authorizer-react";
import { Container, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { bgBG, DataGrid } from "@mui/x-data-grid";
import React from "react";
import {
  useInsertPropertyMutation,
  usePropertiesSubscription,
} from "../__generated__/types";
import { PropertyForm } from "../components/forms/PropertyForm";
import dayjs from "dayjs";
import { Button } from "gatsby-theme-material-ui";
import { useSnackbar } from "notistack";
import { useSelectedPropertyContext } from "../components/SelectedPropertyProvider";
import { translations } from "../translations";

export const CreatePropertyForm = ({ onSubmit }) => {
  const { user } = useAuthorizer();
  const { selected_locale } = useSelectedPropertyContext();
  const { setSelected } = useSelectedPropertyContext();
  const [insertProperty] = useInsertPropertyMutation();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <PropertyForm
      onSubmit={async (values) => {
        const { data } = await insertProperty({
          variables: { object: { ...values, user_id: user?.id } },
        });
        await setSelected(data?.insert_property_one?.id);
        onSubmit?.();
        enqueueSnackbar(
          `${translations[selected_locale].property} '${values.name}' ${translations[selected_locale].is_succesfully_saved}`,
          {
            variant: "success",
          }
        );
      }}
    />
  );
};

const PropertyPage = () => {
  const [open, setOpen] = React.useState(false);
  const { user } = useAuthorizer();
  const { selected_locale } = useSelectedPropertyContext();
  const { data, loading, error } = usePropertiesSubscription({
    variables: {
      user_id: user?.id,
    },
  });
  return (
    <>
      <Container
        sx={{ mt: 2, display: "flex", flexDirection: "column" }}
        maxWidth={false}
      >
        <Button
          color="secondary"
          variant="contained"
          sx={{ mb: 1, alignSelf: "flex-end" }}
          onClick={() => setOpen(true)}
        >
          {translations[selected_locale].add_property}
        </Button>
        <DataGrid
          error={error?.message}
          loading={loading}
          autoHeight
          checkboxSelection={false}
          rows={
            data?.property.map?.((type) => ({
              ...type,
            })) || []
          }
          columns={[
            {
              field: "name",
              headerName: translations[selected_locale].property_name,
              minWidth: 150,
              flex: 1,
            },
            {
              field: "legal_entity",
              headerName: translations[selected_locale].legal_entity,
              minWidth: 150,
              flex: 1,
            },
            {
              field: "legal_entity_id",
              headerName: translations[selected_locale].legal_entity_id,
              minWidth: 150,
              flex: 1,
            },
            {
              field: "iban",
              headerName: "IBAN",
              minWidth: 150,
              flex: 1,
            },
            {
              field: "bic",
              headerName: "BIC",
              minWidth: 100,
              flex: 1,
            },
            {
              field: "email",
              headerName: translations[selected_locale].email,
              minWidth: 150,
              flex: 1,
            },
            {
              field: "phone_number",
              headerName: translations[selected_locale].phone_number,
              minWidth: 150,
              flex: 1,
            },
            {
              field: "address",
              headerName: translations[selected_locale].address,
              minWidth: 150,
              flex: 1,
            },
            {
              field: "check_in",
              headerName: translations[selected_locale].check_in,
              // minWidth: 100,
              flex: 1,
            },
            {
              field: "check_out",
              headerName: translations[selected_locale].check_out,
              // minWidth: 100,
              flex: 1,
            },
            {
              field: "pet_price",
              headerName: translations[selected_locale].pet_price,
              minWidth: 100,
              flex: 1,
            },
            {
              field: "down_payment",
              headerName: translations[selected_locale].deposit,
              minWidth: 100,
              flex: 1,
            },
            {
              field: "created_at",
              headerName: translations[selected_locale].created_at,
              minWidth: 150,
              flex: 1,
              valueFormatter: (params) =>
                `${dayjs(params.value).format("DD-MM-YYYY HH:ss")}`,
            },
            {
              field: "updated_at",
              headerName: translations[selected_locale].updated_at,
              minWidth: 150,
              flex: 1,
              valueFormatter: (params) =>
                `${dayjs(params.value).format("DD-MM-YYYY HH:ss")}`,
            },
            {
              field: "actions",
              sortable: false,
              filterable: false,
              headerName: "",
              minWidth: 150,
              renderCell: (params) => (
                <>
                  <Button
                    color="secondary"
                    variant="outlined"
                    to={`/property/${params.id}`}
                  >
                    {translations[selected_locale].update}
                  </Button>
                </>
              ),
            },
          ]}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          localeText={translations[selected_locale].datagrid}
        />
      </Container>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{translations[selected_locale].add_property}</DialogTitle>
        <DialogContent dividers sx={{ pt: 0 }}>
          <CreatePropertyForm
            onSubmit={() => {
              setOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PropertyPage;
