import { Container, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useSelectedPropertyContext } from "./../components/SelectedPropertyProvider";
import {
  useInsertRoomMutation,
  useRoomSubscription,
} from "../__generated__/types";
import { RoomForm } from "../components/forms/RoomForm";
import { translations } from "../translations";
import { Button } from "gatsby-theme-material-ui";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";

export const CreateRoomForm = ({ onSubmit }) => {
  const { selected, selected_locale } = useSelectedPropertyContext();
  const [insertRoom] = useInsertRoomMutation();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <RoomForm
      onSubmit={async (values) => {
        await insertRoom({
          variables: { object: { ...values, property_id: selected } },
        });
        onSubmit?.();
        enqueueSnackbar(
          `${translations[selected_locale].room_number} '${values.room_number}' ${translations[selected_locale].is_succesfully_saved}`,
          {
            variant: "success",
          }
        );
      }}
    />
  );
};

const RoomPage = () => {
  const [open, setOpen] = React.useState(false);
  const { selected, selected_locale } = useSelectedPropertyContext();
  const { data, loading, error } = useRoomSubscription({
    variables: { property_id: selected },
  });
  return (
    <Container sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
      <Button
        color="secondary"
        variant="contained"
        sx={{ mb: 1, alignSelf: "flex-end" }}
        onClick={() => setOpen(true)}
      >
        {translations[selected_locale].add_room}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{translations[selected_locale].add_room}</DialogTitle>
        <DialogContent dividers sx={{ pt: 0 }}>
          <CreateRoomForm
            onSubmit={() => {
              setOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
      <DataGrid
        error={error?.message}
        loading={loading}
        autoHeight
        checkboxSelection={false}
        rows={
          data?.room.map?.((type) => ({
            ...type,
            room_type: type.room_type.room_type,
          })) || []
        }
        columns={[
          {
            field: "room_number",
            headerName: translations[selected_locale].number_label,
            minWidth: 100,
            flex: 1,
          },
          {
            field: "room_type",
            headerName: translations[selected_locale].room_type,
            minWidth: 100,
            flex: 1,
          },
          {
            field: "created_at",
            headerName: translations[selected_locale].created_at,
            minWidth: 150,
            flex: 1,
            valueFormatter: (params) =>
              `${dayjs(params.value).format("DD-MM-YYYY HH:ss")}`,
          },
          {
            field: "updated_at",
            headerName: translations[selected_locale].updated_at,
            minWidth: 150,
            flex: 1,
            valueFormatter: (params) =>
              `${dayjs(params.value).format("DD-MM-YYYY HH:ss")}`,
          },
          {
            field: "actions",
            sortable: false,
            filterable: false,
            headerName: "",
            minWidth: 150,
            flex: 1,
            renderCell: (params) => (
              <>
                <Button
                  color="secondary"
                  variant="outlined"
                  to={`/rooms/${params.id}`}
                >
                  {translations[selected_locale].update}
                </Button>
              </>
            ),
          },
        ]}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        localeText={translations[selected_locale].datagrid}
      />
    </Container>
  );
};

export default RoomPage;
