import { Grid, Typography } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { Autocomplete, TextField } from "formik-mui";
import { Button } from "gatsby-theme-material-ui";
import React from "react";
import * as Yup from "yup";
import { Percent } from "@mui/icons-material";
import { translations } from "../../translations";
import { useSelectedPropertyContext } from "../SelectedPropertyProvider";
import { TextField as MuiTextField } from "@mui/material";

export const PropertyForm = ({ onSubmit, initialValues = null, onDelete }) => {
  const { selected_locale, currency_code } = useSelectedPropertyContext();
  return (
    <Formik
      initialValues={
        initialValues || {
          name: null,
          email: null,
          phone_number: null,
          address: null,
          check_in: null,
          check_out: null,
          iban: null,
          bic: null,
          legal_entity: null,
          legal_entity_id: null,
          pet_price: null,
          down_payment: null,
          currency_code: "EUR",
        }
      }
      enableReinitialize
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .nullable()
          .required(translations[selected_locale].required_field),
        email: Yup.string()
          .nullable()
          .email(translations[selected_locale].invalid_email)
          .required(translations[selected_locale].required_field),
        phone_number: Yup.string()
          .nullable()
          .required(translations[selected_locale].required_field),
        address: Yup.string()
          .nullable()
          .required(translations[selected_locale].required_field),
        check_in: Yup.string()
          .nullable()
          .required(translations[selected_locale].required_field),
        check_out: Yup.string()
          .nullable()
          .required(translations[selected_locale].required_field),
        iban: Yup.string()
          .nullable()
          .required(translations[selected_locale].required_field),
        bic: Yup.string()
          .nullable()
          .required(translations[selected_locale].required_field),
        legal_entity: Yup.string()
          .nullable()
          .required(translations[selected_locale].required_field),
        legal_entity_id: Yup.string()
          .nullable()
          .required(translations[selected_locale].required_field),
        pet_price: Yup.number()
          .moreThan(
            -1,
            `${translations[selected_locale].pet_price} ${translations[selected_locale].number_positive}`
          )
          .required(translations[selected_locale].required_field),
        down_payment: Yup.number()
          .moreThan(
            -1,
            `${translations[selected_locale].deposit} ${translations[selected_locale].number_positive}`
          )
          .required(translations[selected_locale].required_field),
        currency_code: Yup.string().required(),
      })}
      onSubmit={async (values, formikHelpers) => {
        await onSubmit?.(values);
        formikHelpers.setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid, dirty, values, touched, errors }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Field
                component={TextField}
                name="name"
                label={translations[selected_locale].property_name}
                type="text"
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                component={TextField}
                name="legal_entity"
                label={translations[selected_locale].legal_entity}
                type="text"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                name="legal_entity_id"
                label={translations[selected_locale].legal_entity_id}
                type="text"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                name="email"
                label={translations[selected_locale].email}
                type="text"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                name="phone_number"
                label={translations[selected_locale].phone_number}
                type="text"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                name="address"
                label={translations[selected_locale].address}
                type="text"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                name="check_in"
                label={translations[selected_locale].check_in}
                type="time"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                name="check_out"
                label={translations[selected_locale].check_out}
                type="time"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                component={TextField}
                name="iban"
                label="IBAN"
                type="text"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component={TextField}
                name="bic"
                label="BIC"
                type="text"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                component={TextField}
                name="pet_price"
                label={translations[selected_locale].pet_price}
                type="number"
                required
                fullWidth
                InputProps={{
                  endAdornment: (
                    <Typography style={{ fontWeight: 500 }}>
                      {
                        new Intl.NumberFormat(selected_locale, {
                          style: "currency",
                          currency: currency_code,
                        })
                          .formatToParts(1)
                          .find((x) => x.type === "currency")?.value
                      }
                    </Typography>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component={TextField}
                name="down_payment"
                label={translations[selected_locale].deposit}
                type="number"
                required
                fullWidth
                InputProps={{ endAdornment: <Percent /> }}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component={Autocomplete}
                name="currency_code"
                label={translations[selected_locale].deposit}
                type="number"
                fullWidth
                options={["EUR", "BGN"]}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    name="currency_code"
                    type="text"
                    error={
                      touched["currency_code"] && !!errors["currency_code"]
                    }
                    label={"Currency"}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                disabled={!dirty || !isValid || isSubmitting}
                sx={{ mr: 1 }}
              >
                {translations[selected_locale].submit}
              </Button>
              {onDelete && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => onDelete(values)}
                >
                  {translations[selected_locale].delete}
                </Button>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
