import { Grid } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import { Button } from "gatsby-theme-material-ui";
import React, { FC } from "react";
import * as Yup from "yup";
import { translations } from "../../translations";
import {
  GetRoomTypeSubscription,
  Room_Type_Set_Input,
} from "../../__generated__/types";
import { useSelectedPropertyContext } from "../SelectedPropertyProvider";

type RoomTypeFormType = FC<{
  initialValues?: GetRoomTypeSubscription["room_type_by_pk"];
  onSubmit?: (values: Room_Type_Set_Input) => Promise<void>;
  onDelete?: () => Promise<void>;
}>;

export const RoomTypeForm: RoomTypeFormType = ({
  onSubmit,
  initialValues,
  onDelete,
}) => {
  const { selected_locale } = useSelectedPropertyContext();
  return (
    <Formik
      initialValues={{
        room_type: initialValues?.room_type,
        max_occupancy: initialValues?.max_occupancy,
      }}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        onSubmit?.(values);
        setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        room_type: Yup.string().required(
          translations[selected_locale].required_field
        ),
        max_occupancy: Yup.number().required(
          translations[selected_locale].required_field
        ),
      })}
    >
      {({ isSubmitting, isValid, dirty, setSubmitting }) => (
        <Form>
          <Grid container>
            <Grid item xs={12}>
              <Field
                component={TextField}
                name="room_type"
                label={translations[selected_locale].room_type}
                type="text"
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                name="max_occupancy"
                label={translations[selected_locale].max_occupancy}
                type="number"
                margin="normal"
                fullWidth
              />
            </Grid>
            <Button
              type="submit"
              variant="contained"
              disabled={!dirty || !isValid || isSubmitting}
              sx={{ mr: 1 }}
            >
              {translations[selected_locale].submit}
            </Button>
            {onDelete && (
              <Button
                variant="contained"
                color="error"
                disabled={isSubmitting}
                onClick={async () => {
                  setSubmitting(true);
                  await onDelete();
                  setSubmitting(false);
                }}
              >
                {translations[selected_locale].delete}
              </Button>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
