import {
  Paper,
  Box,
  Chip,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Skeleton,
} from "@mui/material";
import dayjs from "dayjs";
import { Link } from "gatsby";
import { FC } from "react";
import { STATUSES_V2 } from "../components/ReservationForm";
import { Paid } from "@mui/icons-material";
import { useSelectedPropertyContext } from "./SelectedPropertyProvider";

export const StatCard: FC<{
  title: string;
  emptyLabel: string;
}> = ({ title, emptyLabel, reservation, icon, loading }) => {
  const { selected_locale } = useSelectedPropertyContext();
  return (
    <TableContainer component={Paper}>
      <Box
        sx={{
          display: "flex",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            ml: 1,
            p: 2,
            mt: 1,
          }}
        >
          {title} {reservation?.length > 0 && `(${reservation?.length})`}
        </Typography>
        <Box flex={1} />
        <Box sx={{ mr: 3 }}>{icon}</Box>
      </Box>

      <Table>
        <TableBody>
          {reservation?.map(
            ({ id, client, arrival_date, departure_date, status }) => (
              <TableRow
                key={id}
                component={Link}
                to={`/reservations/${id}`}
                style={{ textDecoration: "none" }}
              >
                <TableCell sx={{ pl: 3 }}>
                  <Box>
                    {client.first_name} {client.last_name}
                  </Box>
                </TableCell>
                <TableCell>
                  {dayjs(arrival_date).format("DD MMM")}.{" "}
                  {dayjs(departure_date).format("DD MMM")}.
                </TableCell>
                <TableCell>
                  <Chip
                    label={STATUSES_V2(selected_locale)[status].label}
                    variant="outlined"
                    color={STATUSES_V2(selected_locale)[status].color}
                    size="small"
                  />
                </TableCell>
              </TableRow>
            )
          )}
          {!loading && reservation?.length === 0 && (
            <TableRow>
              <TableCell>
                <Typography>{emptyLabel}</Typography>
              </TableCell>
            </TableRow>
          )}
          {loading && (
            <TableRow>
              <TableCell>
                <Typography>
                  <Skeleton />
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
