import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/system";
import { useSelectedPropertyContext } from "./../components/SelectedPropertyProvider";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import {
  useInsertRoomTypeMutation,
  useRoomTypeSubscription,
} from "../__generated__/types";
import { useAuthorizer } from "@authorizerdev/authorizer-react";
import { RoomTypeForm } from "../components/forms/RoomTypeForm";
import { translations } from "../translations";
import { Button } from "gatsby-theme-material-ui";
import { useSnackbar } from "notistack";

export const CreateRoomTypeForm = ({ onSubmit }) => {
  const { selected, selected_locale } = useSelectedPropertyContext();
  const [insertRoomType] = useInsertRoomTypeMutation();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <RoomTypeForm
      onSubmit={async (values) => {
        await insertRoomType({
          variables: { object: { ...values, property_id: selected } },
        });
        onSubmit?.();
        enqueueSnackbar(
          `${translations[selected_locale].room_type} '${values.room_type}' ${translations[selected_locale].is_succesfully_saved}`,
          {
            variant: "success",
          }
        );
      }}
    />
  );
};

const RoomTypePage = () => {
  const [open, setOpen] = React.useState(false);
  const { selected, selected_locale } = useSelectedPropertyContext();
  const { user } = useAuthorizer();
  const { data, loading, error } = useRoomTypeSubscription({
    variables: { property_id: selected, user_id: user?.id },
  });
  return (
    <>
      <Container sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
        <Button
          color="secondary"
          variant="contained"
          sx={{ mb: 1, alignSelf: "flex-end" }}
          onClick={() => setOpen(true)}
        >
          {translations[selected_locale].add_room_type}
        </Button>
        <DataGrid
          error={error?.message}
          loading={loading}
          autoHeight
          checkboxSelection={false}
          rows={
            data?.room_type.map?.((type, index, arr) => ({
              ...type,
              number: arr.length - index,
              room_count: type.rooms_aggregate.aggregate?.count,
            })) || []
          }
          columns={[
            {
              field: "id",
              headerName: "id",
              hide: true,
            },
            {
              field: "number",
              headerName: translations[selected_locale].number_label,
              minWidth: 50,
              flex: 1,
              filterable: false,
            },
            {
              field: "max_occupancy",
              headerName: translations[selected_locale].max_occupancy,
              minWidth: 100,
              flex: 1,
            },
            {
              field: "room_type",
              headerName: translations[selected_locale].room_type,
              minWidth: 100,
              flex: 1,
            },
            {
              field: "room_count",
              headerName: translations[selected_locale].rooms,
              minWidth: 100,
              flex: 1,
            },
            {
              field: "created_at",
              headerName: translations[selected_locale].created_at,
              minWidth: 100,
              flex: 1,
            },
            {
              field: "updated_at",
              headerName: translations[selected_locale].updated_at,
              minWidth: 100,
              flex: 1,
            },
            {
              field: "actions",
              sortable: false,
              filterable: false,
              headerName: "",
              minWidth: 150,
              flex: 1,
              renderCell: (params) => (
                <>
                  <Button
                    color="secondary"
                    variant="outlined"
                    to={`/room-type/${params.id}`}
                  >
                    {translations[selected_locale].update}
                  </Button>
                </>
              ),
            },
          ]}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          localeText={translations[selected_locale].datagrid}
        />
      </Container>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{translations[selected_locale].add_room_type}</DialogTitle>
        <DialogContent dividers sx={{ pt: 0 }}>
          <CreateRoomTypeForm
            onSubmit={() => {
              setOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RoomTypePage;
