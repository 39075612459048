import { useAuthorizer } from "@authorizerdev/authorizer-react";
import { Paid, FlightLand, FlightTakeoff, MoneyOff } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { Link } from "gatsby-theme-material-ui";
import React from "react";
import { useEffect, useState } from "react";
import { useCurrencyProvider } from "../components/CurrencyPovider";
import { useSelectedPropertyContext } from "../components/SelectedPropertyProvider";
import { StatCard } from "../components/StatCard";
import { translations } from "../translations";
import {
  useStripeCustomerIdSubscription,
  useUpdateUserOnboardedMutation,
  useCurrentReservationsSubscription,
  useArrivingReservationsSubscription,
  useDepartingReservationsSubscription,
  usePendingPaymentReservationsSubscription,
  usePedingFullPaymentReservationsSubscription,
  useAllReservationsSubscription,
  usePropertyExpensesSubscription,
} from "../__generated__/types";
import { StripePaymentPlans } from "./profile";
import { CreatePropertyForm } from "./property";
import { CreateRoomTypeForm } from "./room-type";
import { CreateRoomForm } from "./rooms";

const OnboardingFlow = ({ location, data, loading, error }) => {
  const [step, setSteps] = useState(0);
  const [updateUserOnboarded] = useUpdateUserOnboardedMutation();
  const { user, token } = useAuthorizer();
  const { selected_locale } = useSelectedPropertyContext();
  useEffect(() => {
    if (data?.user_by_pk?.stripe_subscription_id && !loading) {
      setSteps(1);
    }
  }, [data?.user_by_pk?.stripe_subscription_id, loading]);
  const steps = [
    translations[selected_locale].setup_payment,
    translations[selected_locale].add_property,
    translations[selected_locale].add_room_types,
    translations[selected_locale].add_rooms,
  ];
  if (loading) return null;
  if (error) return <div>{error.message} </div>;

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper
          variant="outlined"
          sx={{
            p: 3,
            mb: 3,
          }}
          color="red"
        >
          <Typography variant="h4">
            {translations[selected_locale].welcome}
          </Typography>
          <Typography variant="subtitle2">
            {translations[selected_locale].onboarding_text}
          </Typography>
        </Paper>
        <Stepper activeStep={step} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {step === 0 && (
          <Box sx={{ mt: 2 }}>
            <StripePaymentPlans
              hasura_user_id={data?.user_by_pk?.id}
              stripe_customer_id={data?.user_by_pk?.stripe_customer_id}
              success_url={location.href}
              id_token={token?.id_token}
            />
          </Box>
        )}
        {step === 1 && <CreatePropertyForm onSubmit={() => setSteps(2)} />}
        {step === 2 && <CreateRoomTypeForm onSubmit={() => setSteps(3)} />}
        {step === 3 && (
          <CreateRoomForm
            onSubmit={() =>
              updateUserOnboarded({ variables: { id: user?.id } })
            }
          />
        )}
      </Box>
    </>
  );
};

const DATE_FORMAT = "YYYY-MM-DD";

const exchangeRate = { EUR: 1, BGN: 1.95583 };

const IndexPage = ({ location }) => {
  const { selected, selected_locale } = useSelectedPropertyContext();
  const { user, token } = useAuthorizer();

  const { data, loading, error } = useStripeCustomerIdSubscription({
    variables: { id: user?.id },
  });
  const today = dayjs().format(DATE_FORMAT);
  const yesterday = dayjs().subtract(1, "day").format(DATE_FORMAT);
  const currentReservations = useCurrentReservationsSubscription({
    variables: {
      _lte: yesterday,
      _gte: today,
      property_id: selected,
    },
  });
  const arrivingReservations = useArrivingReservationsSubscription({
    variables: {
      _eq: today,
      property_id: selected,
    },
  });
  const departingReservations = useDepartingReservationsSubscription({
    variables: {
      _eq: yesterday,
      property_id: selected,
    },
  });
  const pendingPaymentReservations = usePendingPaymentReservationsSubscription({
    variables: { property_id: selected },
  });
  const pendingFullPaymentReservations =
    usePedingFullPaymentReservationsSubscription({
      variables: { property_id: selected },
    });
  const allReservations = useAllReservationsSubscription({
    variables: { property_id: selected },
  });
  const expenses = usePropertyExpensesSubscription({
    variables: { id: selected },
  });
  const { currency, currencyCode } = useCurrencyProvider();
  return (
    <>
      <Container sx={{ mt: 2 }}>
        {/* <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
          <Link underline="hover" color="inherit" to="/">
            {translations[selected_locale].home}
          </Link>
        </Breadcrumbs> */}
        {!data?.user_by_pk?.has_onboarded && !loading && (
          <OnboardingFlow location={location} {...{ data, loading, error }} />
        )}
        {data?.user_by_pk?.has_onboarded && (
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12} md={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h6" sx={{}}>
                    {translations[selected_locale].expenses}
                  </Typography>
                  <Box>
                    <Typography variant="h4" fontWeight={"bolder"}>
                      {currency(
                        (expenses.data?.property_by_pk?.expenses.reduce(
                          (acc, curr) =>
                            acc +
                            curr.amount / exchangeRate[curr.currency_code],
                          0
                        ) || 0) * exchangeRate[currencyCode]
                      )}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={6} spacing={1}>
              <Grid item xs={12}>
                <StatCard
                  title={translations[selected_locale].arrivals}
                  emptyLabel={
                    translations[selected_locale].no_arrival_reservations
                  }
                  reservation={arrivingReservations.data?.reservation || []}
                  icon={<FlightLand style={{ fontSize: 60 }} />}
                  loading={arrivingReservations.loading}
                />
              </Grid>
              <Grid item xs={12}>
                <StatCard
                  title={translations[selected_locale].departures}
                  emptyLabel={
                    translations[selected_locale].no_departure_reservations
                  }
                  reservation={departingReservations.data?.reservation || []}
                  icon={<FlightTakeoff style={{ fontSize: 60 }} />}
                  loading={departingReservations.loading}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} md={6} spacing={1}>
              <Grid item xs={12}>
                <StatCard
                  title={translations[selected_locale].waiting_desposit}
                  emptyLabel={translations[selected_locale].no_pending_deposit}
                  reservation={
                    pendingPaymentReservations.data?.reservation || []
                  }
                  icon={<MoneyOff style={{ fontSize: 60 }} color="warning" />}
                  loading={pendingPaymentReservations.loading}
                />
              </Grid>
              <Grid item xs={12}>
                <StatCard
                  title={translations[selected_locale].waiting_full_payment}
                  emptyLabel={
                    translations[selected_locale].no_pending_full_payment
                  }
                  reservation={
                    pendingFullPaymentReservations.data?.reservation || []
                  }
                  icon={<Paid style={{ fontSize: 60 }} color="success" />}
                  loading={pendingFullPaymentReservations.loading}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default IndexPage;
